import { extend } from 'flarum/extend';
import DiscussionList from 'flarum/components/DiscussionList';

// ezoic placeholders
let placeholders = [
    104,
    105,
    106,
    107,
    108,
    109,
    110,
    111,
    112,
    113,
];

// load ezoic
let ezSA = document.createElement('script');
ezSA.src = '//www.ezojs.com/ezoic/sa.min.js';
document.head.appendChild(ezSA);

export default function() {
    extend(DiscussionList.prototype, 'view', function(component) {
        if (!component.children.length) return;

        const start = 0;
        const between = 2;
        const discussions = component.children[0].children;
        let index = 0;

        // don't show to logged-in
        if ('object' === typeof(app.session.user)) return;

        // Insert between posts
        discussions.forEach((post, i) => {
            if (i >= start && (i - start) % between === 0 && i < discussions.length - 1) {
                if (index >= 9) return;

                post.children.push(
                	m.trust('<div id="ezoic-pub-ad-placeholder-' + placeholders[index] + '"></div>')
                );

                index++;
            }
        });

        var t = setInterval(function() {
            if ('undefined' === typeof(ezstandalone)) return;

            if (!ezstandalone.enabled) {
                ezstandalone.define(placeholders);
                ezstandalone.enable();
                ezstandalone.display();
            }

            clearInterval(t);
        }, 1000);
    });
}